#provisions, #tag, #meat {
    text-align: center;
    // min-height: 80px;
}

#hero {
    img {
        margin: 0 auto;
        aspect-ratio: 1200 / 493;
        width: 100%;
    }
}

#mission {
    text-align: center;
    height: 169px;
    background-color: map-get($color, text1);
    h2 {
        padding-top: .44em;
        color: map-get($color, bg);
        font-weight: 200;
        font-size: 3em;
        line-height: 1.2em;
        letter-spacing: .04em;
    }
}

#provisions {
    text-align: center;
    min-height: 80px;
    // margin-top: 5em;

    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: .5em;
        margin: 0 auto;
        width: 1029px;
        margin-top: 5em;

        li {
            display: inline;
            list-style-type: none;
            
            img {
                height: 150px;
                opacity: 50%;
                transition: map-get($time, short);
                
                &:hover {
                   transform: translateY(-10px);
                    opacity: 1;
                }
            }

            .sausage {
                padding-left: 20px;
            }

            h3 {
                text-transform: uppercase;
                tab-size: 1.5em;
                letter-spacing: .1em;
                margin-top: 1em;
            }

            a {
                text-decoration: none;
                color: map-get($color, text1);

                &:hover {
                    color: map-get($color, accent1);
                    text-decoration: underline;
                }
            }
        }
    }
}