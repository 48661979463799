#top-bar {
    position: relative;
    top: 0;
    height: 51px;
    width: 100%;
    background-color: map-get($color, accent-green);
    color: map-get($color, accent-gold);
    z-index: 1;

    .social-wrapper {
        text-align: right;
        padding-right: 45px;
        width: map-get($size, site-width);

        ul {
            list-style-type: none;

            height: 27px;
            padding-top: 10px;

            li {
                display: inline-block;
                position: relative;
                margin-right: 8px;

                // &:first-of-type {
                //     margin-bottom: 3px;
                // }

                // &:last-of-type {
                //     margin-right: unset;
                //     margin-bottom: 1px;
                // }

                img {
                    width: 32px;
                }

                a {
                    span {
                        color: #fff;
                        position: absolute;
                        top: -8px;
                        right: -10px;
                        text-shadow: -2px 1px map-get($color, accent-green);
                        text-align: right;
                        font-size: 12px;
                        font-weight: normal;
                        font-family: 'Arial' san-serif;
                    }
                }

            }
        }

        @include media('<showoff') {
            width: 100%;
        }

    }
    @include media('<tablet') {
        background-color: red;
        display: none;
    }
}

header {
    padding-top: 60px;
    margin-bottom: 130px;


    nav {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 265px 1fr;

        height: map-get($size, nav-height);
        width: 1280px;
        border-radius: 3px;
        background-color: map-get($color, accent-green);

        ul {
            margin: 5px 0 0;
            padding: 0;
            font-size: 1.125em;

            &:first-of-type {
                justify-self: end;
            }

            &:last-of-type {
                justify-self: start;
            }

            li {
                list-style-type: none;
                display: inline;
                font-weight: 400;
                text-transform: uppercase;
                white-space: nowrap;
                padding: 0;
                margin: 0 14px;

                a {
                    color: map-get($color, bg);
                    text-decoration: none;

                    &:hover {
                        color: map-get($color, accent-gold);
                    }
                }
            }
        }

        @include media('<desktop') {
            width: 100%;
            grid-template-columns: 1fr 16vw 1fr;

            ul {
                margin-top: 9px;
                font-size: .95em;

                li {
                    margin-right: 6px;
                }
            }
        }

        @include media('<tablet') {
            padding-top: 0;
            display: none;
        }
    }

    @include media('<tablet') {
        // send header back to top of screen
        padding-top: 0;
        margin-bottom: 12vw;
    }

    .isHome {
        background-color: map-get($color, bg);
        box-shadow: 0 0 .1em rgb(204, 204, 204);

        a {
            color: map-get($color, text1);
        }
    }

    .header-logo {
        margin: -30px 0 0 18px;
        width: 234px;
        // filter: drop-shadow(0 4px 2px rgba(49, 56, 52, 0.1));

        @include media('<desktop') {
            width: 14vw;
            margin-top: -15px;
            margin-left: auto;
            margin-right: auto;
        }

    }

    .social-floater {
        position: relative;
        float: right;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: -90px;
        margin-right: 80px;

        div {
            position: relative;
            margin-right: 6px;

            img {
                height: 32px;
                width: 32px;
            }

            a {

                span {
                    color: map-get($color, accent-green);
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    text-shadow: -1px -1px  #fff;
                    text-align: right;
                    font-size: 12px;
                    font-weight: normal;
                    font-family: 'Arial' san-serif;
                }
            }
        }

        @include media('<desktop') {
            margin-right: 10px;
        }

        @include media('<tablet') {
            display: none;
        }
    }

    .mobile-nav-bar {
        display: none;

        @include media('<tablet') {
            // display: block;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            height: 60px;
            color: map-get($color, bg);
            background-color: map-get($color, accent-green);

            // * {border: 1px solid red;}

            .mobile-hamburger {
                justify-self: start;
                margin: 12px;
            }
            .mobile-logo {
                justify-self: center;
                margin-top: 12px;
                width: 132px;
                z-index: 12;
            }
        }
    }

    #mobileNavLinks {
        display: none;
        // height: 100%;
        color: map-get($color, bg);
        background-color: map-get($color, accent-green);

        position: fixed;
        width: 100vw;
        height: 100%;
        margin: 0 auto;
        top: 60px;
        z-index: 10;

        ul {
            margin: 80px 0 0 0;
            list-style-type: none;

            li {
                padding: 12px 0;
                border-top: 1px solid map-get($color, bg);
                border-bottom: 1px solid map-get($color, bg);

                &:last-of-type {
                    border-bottom: unset;
                }
            }
        }

        a {
            color: map-get($color, bg);
            text-decoration: none;
            text-transform: uppercase;
            font-size: 16px;
        }
        .social-mobile {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin: 0 auto;
            justify-items: center;
            width: 80%;
            margin-top: 20px;

            div {
                img {
                    width: 40px;
                }
            }
        }
    }

    .mobile-hamburger {
        cursor: pointer;
    }
}