// LORE
.lore-hero {
    width: 945px;
    margin: 0 auto;
    margin-top: -50px;
    z-index: 10;
    filter: drop-shadow(0 1px 6px rgba(110, 110, 110, 0.5));

    @include media('<tablet') {
        padding-top: 80px;
    }
}

.inner-lore {
    width: 776px;
    margin: 0 auto;
    padding-bottom: 60px;

    .owners-message {
        display: grid;
        grid-template-columns: 250px auto;
        grid-column-gap: 40px;
        align-items: end;
        width: 100%;
        margin-bottom: 68px;

        @include media('<tablet') {
            grid-template-columns: 1fr;
        }

        .owner-image {

            img {
                border-radius: 0;
                transform: rotate(-3.29deg);
                filter: drop-shadow(-1px 1px 6px rgba(132, 130, 130, 0.797));
            }
        }

        .owner-words {

            h4 {
                font-family: map-get($family, body);
                font-size: 24px;
                line-height: 32px;
                font-weight: 700;
                margin-top: 30px;
                text-transform: uppercase;

                p {
                    margin-bottom: 20px;
                    line-height: 32px;
                }

                @include media('<tablet') {
                    font-size: 20px;
                }
            }
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        margin-bottom: 0;
        border-radius: 3px;
        filter: drop-shadow(0 0 1px rgb(0, 0, 0));
    }

    blockquote {
        margin: 30px 0 0 0;
    }

    blockquote>p {
        font-size: 19px;
        font-weight: 400;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;

        @include media('<tablet') {
            font-size: 12px;
            padding-bottom: 20px;
        }
    }

    h1 {
        color: map-get($color, accent-green);
        font-size: 8em;
        line-height: .8em;
        text-align: center;
        margin-bottom: 20px;
    }

    h2 {
        color: map-get($color, blk);
        text-align: center;
        font-family: map-get($family, body);
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
        margin-top: 10px;
    }

    p {
        font-size: 1.35em;
        line-height: 1.25em;
        font-weight: 300;
        padding-bottom: .9 em;
        // color: map-get($color, accent-green);
        color: map-get($color, blk);
        clear: float;
    }

    figcaption {
        text-transform: uppercase;
        // font-weight: map-get($weight, heavy);
        font-size: 18px;
        text-align: left;
    }

    .figure-full {
        margin: 40px 0 20px 0;

        figcaption {
            text-align: center;
            margin: 10px 0;
        }
    }

    .figure-left {
        float: left;
        margin-right: 40px;
        transform: rotate(-8deg);
    }

    .figure-right {
        float: right;
        margin-left: 40px;
        // margin-right: 40px;
    }

    @include media('<tablet') {
        width: 90%;

        h1 {
            font-size: 12vw;
        }
    }

}

// LURE LAB
#labSteps {

    padding-bottom: 40px;

    .steps-grid {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 80px;
        grid-row-gap: 20px;
        place-items: start;
        width: 100%;
        height: auto;
        margin: 40px 0;

        @include media('<tablet') {
            grid-template-columns: 1fr;
        }

        article {

            width: 100%;

            h2 {
                font-size: 32px;
                line-height: 50px;
                color: map-get($color, text1);

                @include media('<tablet') {
                    margin-left: auto;
                    margin-right: auto;
                    width: 85%;
                }
            }

            p {
                font-size: 18px;
                line-height: 22px;

                @include media('<tablet') {
                    margin-left: auto;
                    margin-right: auto;
                    width: 85%;
                }
            }

            .video {
                margin-left: auto;
                margin-right: auto;
                width: 416px;
                height: 314px;
            }

            @include media('<tablet') {

                h2 {
                    font-size: 24px;
                }

                .video {
                    width: 85%;
                    height: auto;

                    img {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}


// BRAG BOARD
.brag-intro {

}

// SINGLE BRAG
.brag-grid {
    display: grid;
    grid-template-columns: 568px auto;


    h1 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        color: map-get($color, text1);
        margin-bottom: 16px;
    }

    p {
        width: 100%;
        margin: 0;
    }

    @include media('<tablet') {
        grid-template-columns: 1fr;
    }
}


.single-brag-spacer {
    margin-bottom: 40px;
}

#showcase {

    border-bottom: 1px solid #000;
    padding-bottom: 20px;
    margin-bottom: 20px;

    h2 {
        color: map-get($color, text1);
        margin-bottom: 10px;
    }


    .card {
        display: grid;
        grid-template-columns: 600px auto;
        align-items: start;

        @include media('<tablet') {
            grid-template-columns: 1fr;
        }

        h3 {
            font-size: 1.75em;
            line-height: 1.8em;
            margin: 20px 0 0 0;
        }

        .summary {
            font-size: 16px;
            line-height: 1.6rem;
            margin-bottom: 0.5em;
            padding: 0 3em 6px 0;
            text-align: left;
        }

        .bundle {

            img {
                margin: 20px auto 10px auto;
            }

            h3 {
                line-height: 1.6em;
                text-align: center;
                margin-top: 0;
            }

            p {
                padding: 0 40px;
                text-align: center;
                font-weight: 300;
                font-size: 1.06em;
                line-height: 1.2em;
            }
        }
    }
}

// TIPS & TRICKS
.tips {

    ul {

        padding: 40px 20px;

        li {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
        }
    }
}

// FAQ
.faq-content {
    margin-top: 40px;

    details {
        // border: 1px solid #aaa;
        // border-radius: 4px;
        text-align: left;
        margin: 10px 10px 20px 20px;

        h2 {
            display: inline;
            font-family: map-get($family, body);
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
        }
    }

    summary {
        font-weight: bold;
        text-align: left;
        // padding:10px 40px;
    }

    summary::marker {
        color: map-get($color, accent-gold);
        // font-size: 24px;
        // line-height: 28px;
    }

    details[open] {
        padding: .5em;

        div {
            border-top: 1px solid map-get($color, text1);

            p {
                font-size: 18px;
                line-height: 22px;
                // padding: 6px 0;
            }
        }
        // font-size: 18px;
        // line-height: 22px;
        // text-align-last: left;
    }

    details[open] summary {
        border-bottom: 1px solid #aaa;
        // margin-bottom: .5em;
    }
}