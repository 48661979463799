.form {

    text-align: left;
    padding: 40px;
    margin: 0;

    p {
        color: map-get($color, accent-gold);
        padding-bottom: 10px;
        font-size: 18px;
    }


    textarea,
    input {
        width: 100%;
        color: map-get($color, accent-green);
    }

    textarea {
        height: 200px;
    }


    .disclaimer {
        font-size: 14px;
        line-height: 16px;
        padding-top: 20px;
    }

    @include media('<800px') {
        textarea {
            height: 140px;
        }
    }
}