.banner {
    position: relative;
    margin: 0 auto;
    background-image: url('https://via.placeholder.com/1200x524.png');
    background-repeat: no-repeat;
    background-position: center center;
    width: 1076px;
    height: 500px;
    
    h1 {
        position: relative;
        bottom: -320px;
        bottom: -65%;
        width: 100%;
        text-align: center;
        font-family: map-get($family, heading);
        text-align: center;
        background-color: map-get($color, accent-green-a);
    }

    @include media('<desktop') {
        width: 100%;
        height: 50vw;
        background-size: cover;
    }
    
}

// TITLE FOR NO BANNER 
.no-banner {
    position: relative;
    width: 100%;
    text-align: center;
    font-family: map-get($family, heading);
    text-align: center;
    background-color: map-get($color, accent-green);
    line-height: 1.5em;
    padding-bottom: 10px;
}

.banner-home {
    margin: 0 auto;
    background-image: url('/images/hp-hero-temp.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 1076px;
    height: 500px;

    @include media('<tablet') {
        width: 100%;
        height: 80vw;
    }
}

.home-banner-wrapper {
    position: relative;
    width: 700px;
    height: 277px;
    top: 118px;
    background-color: map-get($color, accent-green-a);
    background-blend-mode: multiply;
    margin: 0 auto;
    
    h1 {
        font-size: 1.68em;
        line-height: 1em;
        text-transform: uppercase;
        padding-top: 63px;
        font-weight: 400;
        letter-spacing: .05em;
        text-align: center;

        @include media('<tablet'){
            font-size: 5vw;
            padding-top: 11vw;
        }
        
        span {
            display: block;
            font-size: 2em;
            line-height: .85em;
            padding: 0 2em;
            text-align: center;

            @include media('<tablet'){
                font-size: 9vw;
                padding: 0 40px;
            }
        }
    }
    p {
        font-family: map-get($family, heading);
        font-weight: 400;
        font-size: 2em;
        text-align: center;
        letter-spacing: .025em;
        padding-top: 10px;
        color: map-get($color, accent-gold);
        
        @include media('<tablet'){
            font-size: 4vw;
        }
    }
    
    @include media('<tablet') {
        height: 47vw;
        width: 100%;
        top: 18vw;
    }
}