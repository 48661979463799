dialog {
    z-index: 20;
}

#bragDialog::backdrop {
    backdrop-filter: blur(3px);
}

.letter-dialog {
    background-color: antiquewhite;
}

.petes-letter {
    // position: fixed;
    top: 0;
    border: none;
    margin-top: 60px;
    padding: 40px 140px;
    max-width: 1000px;
    filter: drop-shadow(0 0 12px rgba(26, 26, 26, 0.466));

    @include media('<1000px') {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 8vw;
        padding-right: 8vw;
    }

    .letter-logo {
        margin: 20px auto 40px auto;
        width: 176px;
    }
    .letter-sig {
        width: 184px;
        margin: 20px 0;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 4px;

        &:last-of-type {
            padding-bottom: 80px;
        }
    }
}
.letter-button {
    color: map-get($color, accent-gold);
    background-color: map-get($color, accent-green);
    border: none;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}
.letter-close-button {
    position: fixed;
    top: 40px;
    right: 80px;
    color: map-get($color, accent-gold);
    background-color: map-get($color, accent-green);
    border: none;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;

    @include media('<1000px') {
        top: 10px;
        right: 12px;
    }
}

.submit-dialog {
    color: map-get($color, bg);
    background-color: map-get($color, accent-green);
    width: 80%;
    border: none;
    border-radius: 12px;
    position: fixed;
    opacity: .97;
    top: 2vh;
    width: 660px;
    height: auto;

    box-shadow: (
        0 0 2px map-get($color, accent-gold),
        0 0 18px rgb(3, 26, 5)
        );


    .close-btn {
        position: absolute;
        color: map-get($color, bg);
        background-color: map-get($color, accent-gold);
        border-radius: 3px;
        top: 26px;
        // margin-right: 20px;
        right: 56px;
        border: none;
        padding: 6px 16px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
            background-color: map-get($color, accent-red);
        }
    }

    .submit-btn {
        color: map-get($color, bg);
        background-color: map-get($color, accent-gold);
        border-radius: 3px;
        border: none;
        padding: 6px 16px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
            background-color: map-get($color, accent-red);
        }
    }

    &::backdrop {
        backdrop-filter: blur(3px);
    }

    @include media('<800px') {
        position: fixed;
        top: 0;
        width: 85%;
        height: 90vh;
    }
}