.blog-showcase {

    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include media('<tablet2') {
        grid-template-columns: 1fr;
    }

    // Brag Board v2. Blog List Page
    .blog-list {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        text-align-last: left;

        @include media('<phone') {
            grid-template-columns: 1fr;
        }

        article {
            position: relative;
            transition: map-get($time, short);

            &:hover {
                background-color: lighten(map-get($color, gray2), 10);
            }

            a {

                text-decoration: none;

                img {
                    border: .5px solid map-get($color, article);
                }

                h2 {
                    color: map-get($color, accent-green);
                    font-size: 1.4rem;
                    line-height: 24px;
                    margin-top: 10px;
                }

                p {
                    font-size: 1.05rem;
                    line-height: 1.25rem;
                }

                time {
                    font-size: .8rem;
                    line-height: .8rem;
                    font-weight: 500;
                }

                .summary {
                    font-size: 1.05rem;
                    line-height: 1.25rem;
                    margin: 0;
                    text-decoration: none;
                }

                .read-more {
                    margin-top: .5rem;
                    font-size: 1rem;
                    text-decoration: underline;
                    font-weight: 500;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// NEW 'BLOG ASIDE' BUTTON FOR THE BLOG ASIDE
.blog-aside {
    position: sticky;
    top: 20px;
    margin: 0 auto;
    padding: 0 30px;
    margin-top: 6 px;
    width: 100%;
    border: .5px solid map-get($color, accent-green);
    background-color: lighten(map-get($color, gray2), 12);
    // border-left: 1px solid map-get($color, gray2);

    h3 {
        color: map-get($color, accent-green);
        font-size: 1.6rem;
        margin: 10px 0 0 0;
        text-align: left;
    }

    a {
        text-decoration: none !important;

    }

    p {
        font-size: 1.1rem;
        line-height: 1.3rem;

        &:last-of-type {
            margin-bottom: 30px;
        }
    }

    .featured {
        margin-top: 2rem;

        h3 {
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 500;
        }

        img {
            border: 1px solid map-get($color, accent-green);
            width: 100%;
        }

    }

}

.new-submit-yours {
    background-color: map-get($color, accent-green);
    color: map-get($color, bg);
    font-size: .95rem;
    font-weight: 500;
    padding: 14px 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition: map-get($time, short);

    &:hover {
        background-color: map-get($color, accent-green);
    }
}

.pagination-wrapper {

    padding: 10px 0;

    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    ul {
        list-style-type: none;

        li {
            display: inline;
            margin: 0 10px;
        }
    }
}

.brag-hero {
    padding: 0 40px;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 500px auto;
    gap: 60px;
    align-items: center;

    @include media('<tablet2') {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .brag-image {
        border: 1px solid map-get($color, accent-green);
        width: 100%;
    }

    .brag-meta {


        text-align-last: left;

        h1 {
            color: map-get($color, accent-green);
            font-size: 3.4rem;
            line-height: 2.8rem;
            margin-bottom: 1rem;
            letter-spacing: -.02rem;
        }

        .category {
            font-size: 1rem;
            line-height: 1.2rem;
            font-style: unset;
        }

        // Format Tags/Keywords
        ul {

            margin: 0;
            list-style-type: none;
            margin-bottom: 1rem;

            li {
                display: inline;
                margin-right: 4px;

                &:last-of-type {
                    margin-right: unset;
                }
            }
        }

        time {
            text-align-last: left;
            margin-bottom: 2.4rem;
        }

        p {
            margin-top: 1rem;
            font-style: oblique;
        }

        cite {
        }
    }

}

.new-brag-grid {
    position: relative;
    display: grid;
    padding-bottom: 40px;
    grid-template-columns: 4fr 2fr;
    gap: 40px;

    @include media('<tablet2') {
        grid-template-columns: 1fr;
    }

    .brag-content {

        position: relative;
        img {
            border: .5px solid map-get($color, accent-green);
            margin: 1.5rem auto;
        }

        .sub-nav {
            width: 100%;
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;

            position: relative;
            bottom: 0;

            a {
                font-weight: 500;
                text-decoration: none;
            }
        }
    }

    aside {

        position: sticky;
        top: 20px;
        height: 600px;
        background-color: #f3eeee;
    }
}


// SINGLE SPOON PAGE BRAG LIST
#spoon-brag-board {

    .brags-wrapper {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        justify-content: center;

        .brags {

            a {

                text-decoration: none;
                article {

                    img {
                        border: .5px solid map-get($color, accent-green);
                        margin-bottom: 6px;

                        &:hover {
                            opacity: .9;
                        }
                    }

                    h3 {
                        font-size: 1.1rem;
                        line-height: 1.3rem;
                    }
                }
            }
        }
    }

    .cta {
        margin-top: 1rem;
        text-align: center;
        a {
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }
}