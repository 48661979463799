#wrapper {
    // border: 1px solid rgba(0, 0, 255, 0.294);
    margin: 0 auto;
    // padding-top: 60px;
    text-align: center;
    max-width: map-get($size, site-width);
    min-height: 100vh;
    transition: map-get($time, short);

    background-color: map-get($color, bg);
    background-image: url('/images/map-20.jpg');
    background-size: contain;
    background-attachment: fixed;
    background-position: center top;


    @include media("≥desktop", "<showoff") {
        max-width: 100%;
    }

    @include media("<desktop") {
        max-width: 100%;
        margin: unset;
    }
    
    @include media("<tablet") {
        padding-top: 0;
    }
    
}
.rice-paper {
    background-image: url('/images/rice-paper-bg.jpg');
}