// Reassign Font Styling for homepage
.lure-lore,
.spoon-drawer,
.lure-lab,
.merch,
.brag-board {

    h1,
    h2,
    h3,
    p {
        text-align: center;
    }

    h2 {
        font-size: 4.9em;

        @include media('<tablet2') {
            font-size: 3em;
        }
    }

    p {
        color: map-get($color, accent-gold);
    }
}


//  STYLES FOR GREEN BACKGROUND CALLOUTS
#lureLore,
#lureLab,
#bragBoard {
    // margin-top: 40px;
    background-color: map-get($color, accent-green);
    background-image: url('/images/green-bg6.jpg');
    background-size: cover;
    background-repeat: repeat-y;
    background-position: center center;

    @include media('<tablet2') {
        background-image: unset;
        height: auto;
    }

}

#lureLab {
    margin-top: 0;
    // background-size: cover;

}

#bragBoard {
    margin-top: unset;
}

.lore-grid {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    align-items: center;

    @include media('<tablet2') {
        // grid-template-columns: 1fr;
        width: 90%;
    }

    img {
        padding: 60px 0;

        transform: rotate(-4.6deg);
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));

        @include media('<tablet2') {
            display: none;
        }
    }

    .lore-text {

        margin: 40px 0;
        h2 {

            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
        }

        p {

            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);

            &:last-of-type {
                padding-bottom: 30px;
            }
        }
    }
}

.lab-grid {

    padding : 20px 0 60px 0;
    h2 {
        padding: 20px 0;
    }
    p {
        margin-left: auto;
        margin-right: auto;
        width: 650px;
        padding-bottom: 40px;
        @include media('<tablet2') {
            width: 90%;
        }
    }
    .pics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-items: center;
        width: 868px;
        margin-bottom: 30px;

        @include media('<tablet2') {
            grid-template-columns: 1fr;
            width: 80%;
        }

        img {
            width: 268px;
        }
    }
}



.hp-brag-grid {

    padding : 20px 0 60px 0;
    h2 {
        padding: 20px 0;
    }
    p {
        margin-left: auto;
        margin-right: auto;
        width: 740px;
        padding-bottom: 40px;

        @include media('<tablet2') {
            width: 90%;
        }
    }
    .pics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-items: center;
        width: 868px;
        margin-bottom: 30px;

        @include media('<tablet2') {
            grid-template-columns: 1fr;
            width: 80%;
        }

        img {
            width: 268px;
        }
    }
}

//  STYLES FOR WHITE BACKGROUND CALLOUTS

.spoon-grid {
    display: grid;
    grid-template-columns: 1fr;
    background-image: url('/images/slate-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60px;


    h2 {
        padding: 40px 0 0 0;
    }

    .pics {
        margin: 0 auto;
        padding: 40px 0;
        width: 80%;
    }

}

#merch {
    background-image: url(/images/fish-bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 900px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.merch-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 60px;
    margin-bottom: 60px;

    h2 {
        line-height: 2em;
    }

    @include media ('<tablet2') {
        grid-template-columns: repeat(2, 1fr);
    }
}