#content {
    // border: 1px solid grey;

    position: relative;
    margin: 0 auto;
    padding-top: 40px;
    width: map-get($size, content-width);
    background-color: map-get($color, bg-alpha);

    @include media('<desktop') {
        width: 100%;
        padding: 0;
    }
}

.inner  {
    width: map-get($size, inner-width);
    margin: 0 auto;
    margin-top: 50px;

    ul {
        text-align: left;
        font-size: 1.2em;
    }

    @include media('<tablet') {
        width: 90%;
    }

    // PROP 65 SPECIFIC
    #prop65 {
        h1 {
            font-size: 28px;
            line-height: 30px;
            margin: -40px 20px 20px 40px;

            @include media('<tablet2') {
                margin-top: 18vw;
            }

        }
        p {
            font-size: 18px;
            line-height: 22px;
            margin-left: 40px;
            margin-right: 40px;
            margin-bottom: 0;
            padding-bottom: 40px;
        }

        ul {
            font-size: 18px;
            color: map-get($color, accent-green);
            margin-top: -20px;
            margin-bottom: 0;
            padding-left: 40px;
            padding-bottom: 40px;

            li {
                line-height: 24px;
            }
        }
    }
}


.inner-product  {
    width: map-get($size, inner-spoon);
    margin: 0 auto;

    @include media('<desktop') {
        width: 95%;
    }
}


.article-grid {
    // border: 1px solid orange;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    justify-content: start;

    @include media('<tablet') {
        grid-template-columns: 1fr;
    }

    article {
        width: 405px;
        margin: 20px 0 50px 0;

        @include media('<tablet') {
            width: 85%;
            margin-left: auto;
            margin-right: auto;
        }

        &:nth-child(even) {
            justify-self: end;
        }

        h2 {
            color: map-get($color, text1);
            font-size: 1.75em;
            line-height: 1em;
            text-transform: capitalize;
            margin: 20px 0 10px 10px;
        }

        p {
            font-size: 1.125em;
            line-height: 1.32em;
            padding: 0 10px;

            a {
                text-decoration: underline;
                // color: #086CE0;
                letter-spacing: -.5px;
                color: map-get($color, accent-green);

                &:hover {
                    text-decoration: none;
                    color: map-get($color, accent-gold);
                }

            }

            &:last-of-type {
                font-size: 1em;
                text-transform: uppercase;
                text-align: right;
                margin-top: -5px;
            }
        }

    }
}