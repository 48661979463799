// Typography
$family: (
    heading:    ('DM Serif Text', sans-serif),
    body:       ('Libre Franklin', sans-serif)
);

$weight: (
    normal:           400,
    heavy:            600,
    x-heavy:          800
);

// Time & Duration
$time: (
    short:          .15s,
    medium:         .3s,
    long:          1.0s
);

// Size
$size: (
    site-width:      1440px,
    content-width:   1156px,
    inner-width:     906px,
    inner-spoon:     1075px,
    nav-height:      48px,
    nav-width:       1200px
);

// Misc.
$misc: (
    rounded:        4px,
    content-width:  900px,
    titlespace:     0.05em,
    navheight:      136px
);

// Color Palette
$color: (
    bg:             #ffffff,
    bg-alpha:       rgba(255, 255, 255, 0.85),
    text1:          #131413,
    accent-green:   #0B4424,
    accent-green-a: rgba(11, 68, 36, 0.85),
    accent-gold:    #C49E3B,
    accent-orange:  #C47E5A,
    accent-brass:   #B4A53F,
    accent-red:     #BE1E2D,
    blk:            #000,
    gray1:          #A7AAAA,
    gray2:          #D8D8D8
);