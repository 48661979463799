.scroller {
    position: fixed;
    // margin: 0 auto;
    bottom: 40px;
    right: 6vw;
    width: 40px;
    height: 40px;
    background-color: map-get($color, accent-green);
    border-radius: 5px;
    color: #fff;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.75);
    font-size: 2em;
    line-height: 35px;
    transition: .3s;
    transform: rotate(-90deg);
    text-align: center;
}

.show {
    opacity: 1;
}

.hide {
    opacity: 0;
}

.button {
    text-decoration: none;
    color: map-get($color, bg);
    background-color: map-get($color, accent-red);
    font-size: 1.6em;
    text-transform: uppercase;
    padding: 15px 30px;

    &:hover {
        background-color: map-get($color, accent-gold);
    }
}

.shop-button {
    position: relative;
    text-decoration: none;
    font-family: map-get($family, body);
    font-weight: 700;
    font-size: 1.4em;
    text-transform: uppercase;
    color: map-get($color, bg);
    background-color: map-get($color, accent-green);
    border: none;
    margin: auto;
    padding: 16px 50px;
    transition: map-get($time, short);

    &:hover {
        background-color: map-get($color, accent-gold);
    }
}

.buy-now {
    font-size: 1.188em;
    font-weight: 600;
    color: map-get($color, bg);
    background-color: map-get($color, accent-gold);
    padding: 10px 12px;
    text-decoration: none;
    text-transform: uppercase;
}

// Add to the BUY NOW class to make the Merch CTA beefier
.merch {
    padding: 16px 30px;
}

.promo-button {
    color: map-get($color, bg);
    background-color: map-get($color, accent-gold);
    font-family: map-get($family, body);
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 14px;
    border: none;

    &:hover {
        background-color: map-get($color, accent-red);
    }

    &:active {
        background-color: darken(map-get($color, accent-red), 10);
    }
}
