// STYLES FOR THE SPOONS CATEGORY PAGE
.overview-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    align-items: start;
    margin-top: 30px;
    margin-bottom: 20px;
    margin: 30px auto;

    h3 {
        // color: map-get($color, accent-green);
        font-family: map-get($family, body);
        font-size: 1.125em;
        line-height: 1em;
        font-weight: 500;
        margin-top: .5em;
        text-align: center;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 10px;
        margin: 0 auto;
    }

    p {
        font-size: 18px;
        line-height: 22px;
        margin: 0 auto;
    }

    img {
        margin: 0 auto 16px auto;
    }

    @include media ('<tablet2') {
        grid-template-columns: 1fr;
        width: 80%;
    }
}

.size-chart {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 12px;

    @include media('<tablet') {
        grid-template-columns: 1fr;
    }

    div {
        h2 {
            color: map-get($color, accent-green);
        }
        p {
            font-size: 1rem;;
        }

        &:last-of-type {
            align-self: center;
        }
    }
}

// SPOON CATEGORY GRID
.spoons {

    // border: 1px solid red;
    width: 100%;

    .sub-head {
        text-align: center;
        font-family: map-get($family, body);
        color: map-get($color, bg);
        background-color: map-get($color, accent-green);
        font-size: 1.6em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        padding: 6px 0;
        width: 100%;
    }
    p {
        font-size: 18px;
        line-height: normal;
        margin-top:  1rem;
    }
    .sub-copy {
        margin: 20px 0;
        text-align: center;
    }


    .spoons-grid {
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 24px;
        margin-top: 20px;


        article {

            position: relative;
            margin-bottom: 40px;

            h2 {
                margin: 0 0 10px 0;
                font-family: map-get($family, body);
                color: map-get($color, blk);
                font-size: 1.688em;
                font-weight: 500;
                padding-top: 10px;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
            }

            .badge {
                position: absolute;
                // opacity: .7;
                top: 0;
                left: 0;
                height: 26px;
                width: 100%;
                background-color: map-get($color, accent-green);
                z-index: 10;

                font-size: .8rem;
                font-weight: 600;

                span {
                    line-height: normal;
                    text-transform: uppercase;
                    color: map-get($color, bg);
                    padding-bottom: 10px;
                }

            }

            a {
                text-decoration: none;

                img {
                    // box-shadow: 0 0 2px map-get($color, accent-green);
                    border: .5px solid map-get($color, gray1);
                }
            }
        }

        @include media('<tablet') {
            width: 90%;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('<phone') {
            grid-template-columns: 1fr;
        }
    }
}


// STYLES FOR THE DIFFERENT PRODUCT PAGES
// SPOONS & MERCH (Shared styles across both product types)

// Typography
#product {
    h1 {
        font-family: map-get($family, body);
        font-size: 2em;
        line-height: 1.1em;
        font-weight: 400;
        color: map-get($color, text1);
    }

    h2 {
        font-size: 1.875em;
        color: map-get($color, text1);
        line-height: 2em;
    }

    h3 {
        line-height: 3em;
    }

}





// SPOONS
.detail-grid {
    display: grid;
    grid-template-columns: 46% AUTO;
    grid-column-gap: 36px;
    padding-bottom: 40px;
    border-bottom: 1px solid lighten(map-get($color, text1), 60);

    .pics {
        // border: 1px solid red;
        // width: 95vw;
        width: 100%;

        @include media('<tablet2') {
            width: 97vw;
        }

    }

    @include media('<tablet2') {
        grid-template-columns: 1fr;
    }
}

.magnify {
    position: absolute;
    bottom: 20px;
    right: 20px;

    img {
        width: 24px;
        filter: drop-shadow(1px 1px rgba(69, 69, 69, 0.5));
        // filter: opacity(.9);
    }

}
.pics {

    .hero {
        border: 1px solid rgba(128, 128, 128, 0.25);
        // width: 100%;
    }
    .thumbnails {
        margin-top: 10px;
    }

    .thumbnail-border {
        border: 1px solid rgba(128, 128, 128, 0.262);
    }

}

.text {
    text-align: left;

    .ratings {

        img {
            margin: 8px 1px 16px 0;
            display: inline;
            width: 23px;
        }
    }

    p {
        color: map-get($color, text1);
        font-weight: 300;
        font-size: 1.125em;
        line-height: 1.3em;
        padding-right: 30px;
    }


    .options {
        font-size: 1.063em;
        line-height: 2.0em;
        font-weight: 300;
        margin-bottom: 10px;
        word-spacing: 12px;

        h4,
        h5 {
            font-family: map-get($family, body);
            font-weight: 500;
            word-spacing: normal;
        }

        .price-margin {
            // border: 1px solid red;
            margin-left: 6px;
        }

        h4 {
            font-size: 1.188em;
            line-height: 1.8em;
        }

        h5 {
            font-size: 1.063em;
            font-weight: 600;
            line-height: 1.6em;
            margin: 10px 0 4px 0;
            text-transform: uppercase;
        }

        p {
            font-size: 1.063em;
            line-height: 1em;

            span {
                font-weight: 600;
                line-height: .8em;
                word-spacing: normal;
            }
        }

        ul {
            list-style-type: none;
            font-weight: 300;
            height: 26px;

            // li {
            //     display: inline;
            //     padding-left: 0;
            //     margin-right: 10px;
            // };
        }
    }

    .cta {
        margin: auto;
        width: 100%;

        p {
            padding: 20px 0;
            font-weight: 500;
            font-size: 1.2em;
        }

        button {
            margin-top: 10px;
        }

        .instruction {
            font-size: 14px;
            margin-top: 10px;
        }
    }

}

// EXTAS BELOW THE FOLD //
.extras-grid {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 56px;
    text-align: left;
    margin-top: 20px;
    padding-bottom: 40px;

    @include media('<tablet') {
        grid-template-columns: 1fr;
    }


    p {
        font-size: 1em;
        line-height: 1.2em;
        padding-right: 1em;
        color: map-get($color, text1);

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    ul {
        list-style-type: none;

        li {
            padding: 0 0 2px 0;
            font-weight: 300;
            font-size: 1em;

            span {
                margin-right: 4px;
            }
        }
    }

    .fish-types {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;


        h3 {
            font-family: map-get($family, body);
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            // margin-top: 16px;
        }

        img {
            width: 150px;
            margin-top: 20px;
        }
    }

    hr {
        margin: 40px 0 10px 0;
        // color: map-get($color, text1 );
        color: lighten(map-get($color, text1), 100);
    }

    .ugc {
        width: 92%;
        margin: 0 auto;

        #brag-board {
            text-align: center;
            background-color: rgba(180, 165, 63, 0.5);
            padding-bottom: 16px;

            h2 {
                font-size: 2.625em;
                line-height: 1;
                color: map-get($color, accent-green);
                text-align: center;
                padding: 16px 0 !important;
            }

            .brags {
                margin: 0 auto;
                padding: 8px 29px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 20px;
                justify-items: center;
                align-items: center;
            }

            a {
                text-transform: uppercase;
                text-align: center;
                font-size: 1.125em;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .video {
            position: relative;
            margin-top: 20px;

            .play-icon {
                position: absolute;
                z-index: 2;
                top: 29%;
                left: 33%;

                img {
                    width: 150px;

                    &:hover {
                        opacity: .5;
                    }
                }
            }
        }
    }

}


// MERCH