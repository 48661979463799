.snipcart-cart-summary--edit.snipcart-modal__container {
    box-shadow: 0 2px 8px rgba(75, 75, 75, 0.566);
}

.snipcart-cart-summary-side .snipcart-cart__secondary-header {
    background-color: map-get($color, accent-green);
    color: map-get($color, bg);
}

.snipcart__font--secondary, .snipcart__font--subtitle, .snipcart__font--subtitle-small {
    font-family: map-get($family, heading);
}

.snipcart__font--secondary, .snipcart__font--subtitle, .snipcart__font--subtitle-small {
    font-family: map-get($family, body);
}

// Buttons
.snipcart-button-primary {
    color: map-get($color, bg);
    background-color: map-get($color, accent-green);

    &:hover {
        // color: map-get($color, accent-gold);
        background-color: map-get($color, accent-gold);
        box-shadow: unset;
    }
}

.snipcart-base-button.is-icon-right .snipcart-base-button__label {
    font-weight: 700;
}

.snipcart-button-link {
    color: map-get($color, accent-green);

    &:hover {
        color: map-get($color, accent-gold);
    }
}

.snipcart-form__label {
    font-family: map-get($family, body);
    font-size: .85rem;
}

.snipcart-cart-header .snipcart-modal__close-icon, .snipcart-cart-header__icon {
    color: map-get($color, accent-green);
}

.snipcart-modal__close-title {
    background-color: map-get($color, accent-green);
    font-family: map-get($family, body);
    color: map-get($color, bg);
    font-weight: 600;
    // text-transform: uppercase;
    padding: 4px 12px 24px 12px;
    border-radius: 5px;
}