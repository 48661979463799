#merch-content {

    padding-bottom: 60px;

    h2 {
        text-align: center;
        font-size: 96px;
        line-height: 200px;
    }
    
}
.shirts-grid {
    
    // border: 1px solid red;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 60px;
    // background-color: orange;
    
    h3 {
        font-family: map-get($family, body);
        text-align: center;
        margin-bottom: 4px;
        color: map-get($color, accent-gold);
        background-color: map-get($color, accent-green);
        text-transform: uppercase;
    }

    figure {
        border: 1px solid map-get($color, accent-green);
        margin-bottom: 20px;
    }

    @include media('<tablet2') {
        grid-template-columns: 1fr;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}