// Libs
@import '/libs/include-media';
@import 'libs/vars';
// Layout
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/homepage';
@import 'layout/content';
@import 'layout/footer';
@import 'layout/blog';
// Pages
@import 'layout/pages/home';
@import 'layout/pages/single';    // Styles for most SINGLE pages
@import 'layout/pages/products';
@import 'layout/pages/merch';
// Base
@import '/base/page';
@import '/base/reset';
@import '/base/typography';
// Components
@import '/components/snipcart';
@import '/components/banner';
@import '/components/button';
@import '/components/form';
@import '/components/modal';

// Let's load some fonts
// @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Libre+Franklin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,300;0,305;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap');


// Utlity
.hidden {
    display: none;
}