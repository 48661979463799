footer {
    height: 355px;
    width: 100%;
    background-color: map-get($color, accent-green);
    color: map-get($color, accent-gold);

    img {
        margin: 0 auto;
        padding-top: 60px;
    }

    a {
        color: map-get($color, accent-gold);
    }

    .copyright {
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;

        a {
            margin-right: 6px;

            &:last-of-type {
                margin-right: unset;
            }
        }
    }
}