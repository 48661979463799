html { font-size: 16px; }
body, input, select, textarea {
    font-family: map-get($family, body);
    font-weight: map-get($weight, normal);
    font-size: 1rem;
    line-height: 2.15;
    color: map-get($color, text1);
}
h1, h2, h3, h4, h5, h6 {
    font-family: map-get($family, heading);
    font-weight: map-get($weight, normal);
    text-align: left;
}
h1 {
    font-size: 5.9em;
    line-height: 1.4em;
    color: map-get($color, accent-gold);

    @include media('<tablet') {
        font-size: 10vw;
    }
    
}
h2 {
    font-size: 2.3em;
    line-height: 1.2em;
    color: map-get($color, accent-gold);
}
p {
    // font-size: 1.5em;
    font-size: 1.3em;
    line-height: 1.6rem;
    margin-bottom: .5em;
    color: map-get($color, accent-green);
    text-align: left;
}
a {
    text-decoration: underline;
    transition: map-get($time, short);
    color: map-get($color, accent-green);
}
a:hover {
    text-decoration: none;
}
blockquote {
    color: map-get($color, text3);
    padding: 0 1em; 
    margin: 2em 1em;
    font-size: 1.1em;
}
cite {
    font-family: map-get($family, body);
    font-weight: map-get($weight, normal);
    font-style: normal;
}
ul {
    margin: 0 0 1em 20px;
    padding: 0;
}
li {
    // padding-left: .5em;
}
